<template>
  <div class="calendar-main">
    <div class="calendar-list" v-if="companyList.length > 0">
      <div
        class="each-list"
        v-for="item in companyList"
        :key="item.id"
        @click="calendarDeatil(item.id, item.stockCode)"
      >
        <div class="simple-name">
          <img
            class="company-logo"
            :src="item.logo"
            alt=""
            @error="item.error = true"
            v-if="!item.error && item.logo"
          />
          <img
            class="company-logo"
            src="~@/assets/img/person/company.svg"
            alt=""
            v-else
          />
          <div class="company-simple" v-if="item.type === '上市公司'">
            <p class="name">
              {{ item.shortName
              }}<i v-if="item.attestationFlag === '已认证'"></i>
            </p>
            <p class="code">{{ item.stockCode }}</p>
          </div>
          <div class="company-simple item_name" v-else>
            <p class="name">
              <span
                >{{ cutstr(item.name, 30)
                }}<i v-if="item.attestationFlag === '已认证'"></i
              ></span>
            </p>
          </div>
        </div>
        <div class="company-name">
          <p class="complete-name" v-if="item.type === '上市公司'">
            {{ item.name }}
          </p>
          <p
            class="synopsis-text"
            v-else
            v-html="setSynopsis(item.propertyList)"
          ></p>
          <div class="industry-address">
            <p class="industry-text">{{ item.industry }}</p>
            <p class="address-text">
              <span>{{ item.address }}</span>
              <span
                class="collect"
                :class="item.attention ? 'iscollect' : ''"
                @click.stop="collectFn(item)"
              ></span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <NoData v-else />
  </div>
</template>
<script>
import NoData from "@/components/NoData";
import { collectRold } from "@/api/rold";
export default {
  components: { NoData },
  props: {
    companyList: {
      type: Array,
      default: () => [],
    },
    tabList: {
      type: Array,
      default: () => [],
    },
    itemType: {
      type: String,
      default: "",
    },
  },
  methods: {
    // 跳转详情
    calendarDeatil(id, stockCode) {
      var detailUrl;
      if (stockCode) {
        detailUrl = this.$router.resolve({
          path: "/enterprise/deatil",
          query: {
            id: id,
            columnId: this.$route.query.columnId,
            stockCode: stockCode,
          },
        });
      } else {
        detailUrl = this.$router.resolve({
          path: "/enterprise/deatil",
          query: { id: id, columnId: this.$route.query.columnId },
        });
      }
      window.open(detailUrl.href, "_blank");
    },
    // 非上市公司名称显示固定的字符长度
    cutstr(str, len) {
      var str_length = 0;
      var str_len = 0;
      var str_cut = new String();
      str_len = str.length;
      for (var i = 0; i < str_len; i++) {
        var a = str.charAt(i);
        str_length++;
        if (escape(a).length > 4) {
          //中文字符的长度经编码之后大于4
          str_length++;
        }
        str_cut = str_cut.concat(a);
        if (str_length >= len) {
          return str_cut;
        }
      }
      //如果给定字符串小于指定长度，则返回源字符串；
      if (str_length < len) {
        return str;
      }
    },
    // 设置非上市公司的简介
    setSynopsis(name) {
      var synopsis = "";
      if (name) {
        name.forEach((item) => {
          if (item.name == "简介") {
            synopsis = item.content
              .replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g, "")
              .replace(/&nbsp;/gi, "");
          }
        });
      }

      return synopsis;
    },
    // 收藏
    collectFn(item) {
      if (this.$store.state.token) {
        if (this.$store.state.token.indexOf("BC_") != -1) {
          // this.abnormalTip = true;
          // this.abnormalTxt = '企业用户不可收藏，请';
          // this.isLoginLink = true;
          this.$message({
            tipTxt: "企业用户不可收藏，请",
            isLink: true,
            loginTxt: "登录个人账户",
          });
        } else {
          collectRold({ id: item.id, type: 11 })
            .then((res) => {
              console.log(res);
              item.attention = !item.attention;
            })
            .catch(() => {});
        }
      } else {
        this.$router.push("/login");
      }
    },
  },
};
</script>
<style lang="less" scoped>
.calendar-main {
  margin: 0 auto;
  padding-bottom: 25px;
  padding-top: 41px;
  width: 1200px;
}
.calendar-list {
  font-size: 0;
  .each-list {
    margin-bottom: 32px;
    width: 269px;
    // height: 169px;
    display: inline-block;
    vertical-align: top;
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.09);
    cursor: pointer;
    &:not(:nth-of-type(4n)) {
      margin-right: 41px;
    }
    &:not(:nth-of-type(2n)) {
      .simple-name {
        background: url("~@/assets/img/calendar/company_bg1.png");
      }
    }
    &:not(:nth-of-type(2n + 1)) {
      .simple-name {
        background: url("~@/assets/img/calendar/company_bg2.png");
      }
    }
    .simple-name {
      padding: 21px 0 13px 22px;
      width: 247px;
      height: 54px;
      .company-logo {
        width: 54px;
        height: 54px;
        display: inline-block;
        vertical-align: middle;
        border-radius: 50%;
        background: gray;
      }
      .company-simple {
        margin-left: 10px;
        display: inline-block;
        vertical-align: middle;
        .name {
          line-height: 26px;
          font-size: 20px;
          font-weight: bold;
          color: #ffffff;
          i {
            margin-left: 5px;
            width: 14px;
            height: 16px;
            display: inline-block;
            background: url("~@/assets/img/calendar/attestation.svg");
          }
        }
        .code {
          font-size: 20px;
          font-weight: 300;
          color: #ffffff;
        }
      }
      .item_name {
        .name {
          // position: relative;
          // display: flex;
          span {
            // display: inline;
            width: 164px;
            max-height: 52px;
            line-height: 26px;
            display: inline-block;
            font-size: 20px;
            overflow: hidden;
            // text-overflow:ellipsis;
            // display: -webkit-box;
            // -webkit-line-clamp: 2;
            // -webkit-box-orient: vertical;
            word-break: break-all;
          }
          i {
            display: inline-block;
            // position: absolute;

            // bottom: 0;
          }
        }
      }
    }
    .company-name {
      padding: 13px 20px;
      .complete-name {
        margin: 0 0 13px 0;
        height: 48px;
        font-size: 18px;
        color: #020202;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
      .synopsis-text {
        margin-bottom: 13px;
        height: 48px;
        line-height: 24px;
        font-size: 14px;
        color: #999999;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
      .industry-address {
        line-height: 19px;
        font-size: 14px;
        color: #999999;
        .industry-text {
          margin-bottom: 8px;
          height: 19px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .address-text {
          height: 19px;
          .collect {
            float: right;
            margin-top: 1px;
            width: 16px;
            height: 16px;
            cursor: pointer;
            background: url("~@/assets/img/calendar/collect.svg");
            &.iscollect {
              background: url("~@/assets/img/calendar/collected.svg");
            }
          }
        }
      }
    }
  }
}
</style>
