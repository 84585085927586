<template>
  <div class="roadshow-index">
    <div class="video">
      <div v-if="roldList.length">
        <div class="video-item" v-for="item in roldList" :key="item.id">
          <div class="video-item-content">
            <a
              :to="`/pages/luyan-page/luyanDetails?id=${item.id}`"
              target="_blank"
              @click="toDetail(item)"
            >
              <div class="img_box">
                <img
                  :src="item.thumb"
                  @error="item.error = true"
                  v-if="!item.error"
                />
                <img src="~@/assets/img/indexImg/itembg.png" alt="" v-else />
                <em
                  class="tip"
                  v-if="
                    getItemType(item.templateId, item.publishedTime) ===
                      '活动预告'
                  "
                  >活动预告</em
                >
              </div>
            </a>
            <div class="item-info">
              <a
                :to="`/pages/luyan-page/luyanDetails?id=${item.id}`"
                target="_blank"
                @click="toDetail(item)"
              >
                <h2>{{ item.title }}</h2>
              </a>
              <div class="item-footnote">
                <div class="footnote-scoure">
                  <span class="footnote-company-name">
                    <span class="footnote-company-nameData">{{
                      item.officeName
                    }}</span>
                    <template v-if="item.officeCode">
                      <span class="footnote-company-nameData"
                        >({{ item.officeCode }})</span
                      >
                    </template>
                  </span>
                  <span
                    class="collect-start-active"
                    v-if="item.collectStatus"
                    @click="collectRold(item)"
                  ></span>
                  <span
                    class="collect-start"
                    v-else
                    @click="collectRold(item)"
                  ></span>
                </div>
                <div class="footnote-timer">{{ item.publishedTime }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NoData v-else />
    </div>
  </div>
</template>
<script>
import NoData from "@/components/NoData";
import { collectRold } from "@/api/rold";
import BuryingPont from "@/mixins/buryingPoint.js";
export default {
  components: { NoData },
  mixins: [BuryingPont],
  props: {
    roldList: {
      type: Array,
      default: () => [],
    },
    tabList: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    getItemType(type, publishedTime) {
      if (new Date(publishedTime).getTime() > new Date().getTime())
        return "活动预告";
      var data = this.tabList.filter((item) => {
        return item.id == type;
      });
      if (data.length) return data[0].name;
    },
    //收藏
    collectRold(item) {
      if (this.$store.state.token) {
        if (this.$store.state.token.indexOf("BC_") != -1) {
          this.$message({
            tipTxt: "企业用户不可收藏，请",
            isLink: true,
            loginTxt: "登录个人账户",
          });
        } else {
          collectRold({
            id: item.id,
            type: "10",
          }).then((res) => {
            if (res.code == 0) {
              // 收藏埋点
              if (!item.collectStatus) {
                this.sensors.track("LY_collection_click", {
                  AppName: this.$store.state.AppName,
                  click_content: "收藏",
                  collect_title: item.title,
                  is_login: true,
                  platform: this.$store.state.platform,
                });
              }
              // 取消收藏埋点
              if (item.collectStatus) {
                this.sensors.track("LY_cancel_collection_click", {
                  AppName: this.$store.state.AppName,
                  click_content: "取消收藏",
                  collect_title: item.title,
                  is_login: true,
                  platform: this.$store.state.platform,
                });
              }
              this.$emit("upadteRoldList", item.id);
            }
          });
        }
      } else {
        this.$router.push("/login");
      }
    },
    // 跳转路演详情
    async toDetail(item) {
      let isIng = false;
      if (item.endTime) {
        const year = new Date().getFullYear();
        const month = new Date().getMonth() + 1;
        const day = new Date().getDate();
        const hour = new Date().getHours();
        const minute = new Date().getMinutes();
        if (year > item.endTime[0]) {
          isIng = false;
        } else {
          isIng = true;
          if (month > item.endTime[1]) {
            isIng = false;
          } else {
            isIng = true;
            if (day > item.endTime[2]) {
              isIng = false;
            } else {
              isIng = true;
              if (hour > item.endTime[3]) {
                isIng = false;
              } else {
                isIng = true;
                if (minute > item.endTime[4]) {
                  isIng = false;
                } else {
                  isIng = true;
                }
              }
            }
          }
        }
      }
      if (
        item.description &&
        item.description.indexOf("https://wx.easy-board.com.cn") > -1 &&
        (item.startTime || item.endTime) &&
        isIng
      ) {
        if (!this.$store.state.token) {
          this.$router.push("/login");
          return;
        }
        let res;
        if (this.$store.state.token.indexOf("BC_") != -1) {
          res = await this.$store.dispatch("getBusinessInfo");
        } else {
          res = await this.$store.dispatch("getUserInfo");
        }
        const url = item.description?.indexOf("?" > -1)
          ? `${item.description}&userId=${res.userId}&realName=${res.username}`
          : `${item.description}?userId=${res.userId}&realName=${res.username}`;
        window.open(url, "_blank");
        return;
      }
      var detailUrl = this.$router.resolve({
        path: "/pages/luyan-page/luyanDetails",
        query: { id: item.id, columnId: 452 },
      });
      window.open(detailUrl.href, "_blank");
    },
  },
};
</script>
