<template>
  <div class="home-page">
    <div class="home-banner swiper-container">
      <div class="swiper-wrapper" style="position: relative;">
        <div
          class="swiper-slide"
          v-for="(item, index) in swiperArr"
          :key="index"
        >
          <a
            :href="item.url"
            class="banner-img"
            target="_blank"
            :style="{ backgroundImage: `url(${item.host}${item.image})` }"
            @click="
              sensors.track('LY_home_click', {
                AppName: this.$store.state.AppName,
                click_content: 'banner',
                page_id: '',
                is_login: false,
                platform: this.$store.state.platform,
              })
            "
          >
          </a>
        </div>
      </div>
      <div class="swiper-pagination home-pagination"></div>
    </div>
    <!-- 路演列表 -->
    <div v-for="(tab, index) in tabData" :key="index">
      <div v-if="tab.title === '近期活动'">
        <div class="title-box">
          <div class="tit">{{ tab.title }}</div>
          <div @click="toPage('/pages/index/index?columnId=452')" class="more">
            查看更多 >
          </div>
        </div>
        <RoadshowList
          :roldList="roldList"
          :tabList="tabList"
          @upadteRoldList="upadteRoldList"
        />
      </div>
      <div v-else-if="tab.title === '推荐活动'">
        <div v-if="roldListTop.length">
          <div class="title-box">
            <div class="tit">{{ tab.title }}</div>
            <div @click="toPage('/pages/index/index?columnId=452')" class="more">
              查看更多 >
            </div>
          </div>
          <RoadshowList
            :roldList="roldListTop"
            :tabList="tabList"
            @upadteRoldList="upadteRoldList"
          />
        </div>
      </div>
      <div v-else-if="tab.title === '企业中心'">
        <div class="title-box">
          <div class="tit">{{ tab.title }}</div>
          <div @click="toPage('/enterprise?columnId=457')" class="more">
            查看更多 >
          </div>
        </div>
        <Calendar :companyList="companyList" />
      </div>
      <div v-else>
        <div class="title-box">
          <div class="tit">{{ tab.title }}</div>
          <div
            v-if="tab.itemList[0] && tab.itemList[0].link"
            class="more"
            @click="toUrl(tab.itemList[0].link)"
          >
            查看更多 >
          </div>
        </div>
        <div class="entrance-box">
          <div
            v-for="item in tab.itemList"
            :key="item.id"
            class="entrance"
            @click="toUrl(item.link)"
          >
            <img :src="item.backUrl" class="back-img" />
            <div class="tit">{{ item.title }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "@/assets/css/index.less";
import "swiper/dist/idangerous.swiper.css";
import Swiper from "swiper/dist/idangerous.swiper.js";
import { setCatalogTree } from "@/utils/store-data";
import RoadshowList from "@/components/RoadshowList";
import Calendar from "@/components/Calendar";
import {
  getBannerList,
  getRoldTabList,
  getRoldList,
  getRoldListTop,
  getIndexData,
} from "@/api/rold";
import { getCompanyList } from "@/api/calendar";
import BuryingPont from "@/mixins/buryingPoint.js";
// import Tips from '@/components/Tips'
export default {
  mixins: [BuryingPont],
  components: { RoadshowList, Calendar },
  data() {
    return {
      tabData: [],
      form: {
        pageNo: 1,
        pageSize: 4,
      },
      tabList: [],
      roldList: [],
      roldListTop: [],
      companyList: [],
      swiperArr: [],
    };
  },
  beforeRouteLeave(to, from, next) {
    window.removeEventListener("scroll", this.scrollFns);
    next();
  },
  created() {
    setCatalogTree(this.$store);
    this.getBannerList();
    this.getIndexData();
    this.getRoldTabList();
    this.getRoldList();
    this.getRoldListTop();
    this.getCompanyList();
  },
  mounted() {},
  methods: {
    getBannerList() {
      getBannerList()
        .then((res) => {
          this.swiperArr = res.data;
        })
        .finally(() => {
          new Swiper(".home-banner", {
            autoplay: 5000,
            speed: 300,
            loop: this.swiperArr.length > 1 ? true : false,
            pagination: ".home-pagination",
            paginationClickable: true,
            autoplayDisableOnInteraction: false,
            onSlideChangeEnd: function(swiper) {
              swiper.startAutoplay();
            },
          });
        });
    },
    //获取tab列表
    getRoldTabList() {
      getRoldTabList().then((res) => {
        this.tabList = res.data;
      });
    },
    // 获取首页数据
    getIndexData() {
      getIndexData().then((res) => {
        this.tabData = res.data.filter((item) => {
          return item.isUse === 1
        });
      });
    },
    //获取路演列表
    getRoldList() {
      getRoldList(this.form).then((res) => {
        if (res.data && res.data.length) {
          res.data.forEach((item) => (item.error = false));
          this.roldList = res.data;
        }
        // 浏览埋点
        this.sensors.track("LY_view_list_page", {
          AppName: this.$store.state.AppName,
          page_id: "",
          type: "视频",
          number: res.count,
          total_page:
            res.count % this.form.pageSize <= 0
              ? res.count / this.form.pageSize
              : parseInt(res.count / this.form.pageSize) + 1,
          is_login: false,
          platform: this.$store.state.platform,
        });
      });
    },
    //获取路演列表
    getRoldListTop() {
      getRoldListTop(this.form).then((res) => {
        if (res.data && res.data.length) {
          res.data.forEach((item) => (item.error = false));
          this.roldListTop = res.data;
        }
      });
    },
    // 获取企业
    getCompanyList() {
      getCompanyList(this.form).then((res) => {
        if (res.data && res.data.length) {
          res.data.forEach((item) => (item.error = false));
          this.companyList = res.data;
        }
      });
    },
    toPage(url) {
      this.$router.push(url);
    },
    toUrl(url) {
      if (!url) return;
      window.open(url, "_blank");
    },
    // 更新列表收藏状态
    upadteRoldList(id) {
      this.roldListTop.forEach((item) => {
        if (item.id === id) {
          item.collectStatus = !item.collectStatus;
        }
      });
      this.roldList.forEach((item) => {
        if (item.id === id) {
          item.collectStatus = !item.collectStatus;
        }
      });
    },
  },
};
</script>
